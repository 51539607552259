<template lang="pug">
    section#banner
        .wrapper
            router-link(:to="content.backTo.to", v-if="content.backTo").breadcrumb
                FontAwesomeIcon(:icon="icons.faArrowLeft")
                span Voltar {{ content.backTo.page }}
            h1 {{ content.title.pt }}
            h2(v-if="content.description && content.description.pt") {{ content.description.pt }}
            slot
            //- form(@submit.prevent="send", v-if="content.formulario.search")
                .input(v-if="content.formulario.search !== undefined")
                    input(id="input_search", name="input_search", v-model="content.formulario.search")
                    button(type="submit")
                        FontAwesomeIcon(:icon="icons.faMagnifyingGlass")
                .select(v-if="content.formulario.setor !== undefined")
                    select(v-model="content.formulario.setor", @change="send")
                        option(:value="null") Setor
                        option(v-for="setor in content.setores", :value="setor.value") {{ setor.name }}
                .select(v-if="content.formulario.categoria !== undefined")
                    select(v-model="content.formulario.categoria", @change="send")
                        option(:value="null") Categoria
                        option(v-for="categoria in content.categorias", :value="categoria.value") {{ categoria.name }}
</template>

<script>
import { props } from '@/mixins/components'
import { faArrowLeft, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import qs from 'qs'

export default {
  name: 'section-banner-geral',
  props,
  data () {
    return {
      icons: {
        faArrowLeft,
        faMagnifyingGlass
      }
    }
  },
  computed: {
    content () {
      let backTo = {
        to: { name: 'Home' },
        page: 'à Página Inicial'
      }
      if (this.$route.query.backTo) { backTo = qs.parse(this.$route.query.backTo) } else if (this.contents.backTo) { backTo = this.contents.backTo }

      return Object.assign({}, {
        backTo,
        categorias: [],
        description: '',
        formulario: {},
        setores: [],
        title: { pt: '' }
      }, this.contents.content)
    }
  },
  methods: {
    send () {
      this.$emit('submit')
    }
  }
}
</script>

<style lang="stylus" scoped src="./BannerGeral.styl"></style>
